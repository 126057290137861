<template>
  <Page
    :title="productTitle"
    :description="product && product.description"
    :og-image="defaultImage"
  >
    <div
      v-if="product"
      class="c-item"
    >
      <BannerImageAndTitle
        :background="[
          {
            url: $t('widgets.foodDetails.bannerImg[0].url'),
            format: $t('widgets.foodDetails.bannerImg[0].format'),
            altText: $t('widgets.foodDetails.bannerImg[0].altText'),
          },
          {
            url: $t('widgets.foodDetails.bannerImg[1].url'),
            format: $t('widgets.foodDetails.bannerImg[1].format'),
          },
        ]"
        :banner-title="productTitle"
        navigate-back
      />

      <!--Todo: remove v-if when loader state is fixed-->
      <div v-if="Object.keys(product).length">
        <SectionContainer
          class="c-item__sectionContainer"
          size="md"
        >
          <header class="c-item__selectedRestaurant">
            <h1>{{ selectedRestaurant.name }}</h1>
            <p>{{ selectedRestaurant.address }}, {{ selectedRestaurant.city }}</p>
          </header>
          <div class="c-item__gridContainer">
            <div class="c-item__gridItem">
              <Typography
                class="h-mb-20"
                variant="subtitle2"
                v-text="productTitle"
              />
              <span
                class="-subTitle"
                v-text="$t('widgets.foodDetails.ingredients')"
              />
              <Typography
                class="h-mb-20"
                variant="body1"
                v-text="product?.ingredients.join(', ')"
              />
              <span
                class="-subTitle"
                v-text="$t('widgets.foodDetails.recipe')"
              />
              <Typography
                variant="body1"
                v-text="product?.description"
              />
            </div>
            <div class="c-item__gridItem">
              <ResponsiveImage
                v-if="product"
                class="c-item__img"
                :images="product?.images"
                rounded
              />
            </div>
            <div class="c-item__gridItem" />
            <div class="c-item__gridItem">
              <Allergens :allergens="product?.allergens" />
            </div>
            <div class="c-item__gridItem">
              <div
                class="c-item__ingredientsSection"
                v-for="({name, min, max, variants, id: catId}) in product.variantCategories"
                :key="name"
              >
                <div v-if="variants.length">
                  <Typography
                    variant="subtitle3"
                    v-text="name"
                  />
                  <div v-if="max > 1">
                    <PdpIngredientsList
                      :key="catId"
                      :group-name="generateKey(name)"
                      :checkboxes-list="variants"
                      :min="min"
                      :max="max"
                      @selected="getSelectedCheckBoxVal($event, catId)"
                    />
                  </div>

                  <div
                    v-if="min === 1 && max === 1"
                    class="c-item__base-radio"
                  >
                    <BaseRadio
                      v-for="(radio, ind) in variants"
                      :key="radio.id"
                      :text="radio.name"
                      :value="radio"
                      from-pdp
                      :id="`75d9e360-1e33-11ec-9621-0242ac13000${ind}`"
                      name="variant-radio"
                      :checked="selectedVariants[catId]?.includes(radio)"
                      :content="radio.price_plus && convertToNumber(radio.price_plus)"
                      v-model="selectedVariants[catId]"
                    />
                  </div>
                  <div v-if="min === 0 && max === 1">
                    <BaseSelect
                      :options="getOptions(variants)"
                      from-pdp
                      v-model="selectedVariants[catId]"
                      name="variant-select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionContainer>
        <AddToCart
          @qty="getQty"
          :sub-total="subTotal"
          :add-to-cart="addToCart"
          :disabled="!product?.available"
        />
      </div>
    </div>
  </Page>
</template>

<script setup>

import {
  computed, ref, onMounted, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import Page from '@/components/Page.vue';
import BannerImageAndTitle from '@/components/banner/BannerImageAndTitle.vue';
import BaseRadio from '@/components/common/BaseRadio.vue';
import BaseSelect from '@/components/common/BaseSelect.vue';
import ResponsiveImage from '@/components/common/ResponsiveImage.vue';
import PdpIngredientsList from '@/layouts/PdpIngredientsList.vue';
import { convertToNumber, generateKey } from '@/mixins/utils';
import AddToCart from '@/views/Food/AddToCart.vue';
import Allergens from '@/views/Food/Allergens.vue';

const route = useRoute();
const store = useStore();
const product = computed(() => store.state.products.product);
const productTitle = computed(() => (product.value && product.value.name) || 'product-title');
const selectedVariants = ref([]);
const selectedRestaurant = computed(() => store.state.restaurant.selectedRestaurant);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const cartId = computed(() => store.state.cart.cart_items.cart_id);
const deliveryType = computed(() => store.state.order.orderData.delivery_type);
const variantTotal = ref(0);
const qty = ref(1);

const defaultImage = product.value
  && Array.isArray(product.value.images) && (product.value.images[0].url || null);
const getQty = val => {
  qty.value = val;
};
const calculateTotal = () => ((Number(product.value.price) + Number(variantTotal.value))
        * Number(qty.value)).toFixed(2);
const subTotal = computed(() => calculateTotal());

onMounted(() => {
  store.dispatch(
    'products/getSingleProduct',
    `/${route.params.shopId}/${route.params.deliveryType}/product/${route.params.productId}`,
  );
});

const getSelectedCheckBoxVal = (val, idx) => {
  selectedVariants.value[idx] = val;
};

watch(() => [ ...selectedVariants.value ],
  currState => {
    const total = [];

    currState.forEach(items => {
      if (items && items.length) {
        items.forEach(d => {
          total.push(Number(d.price_plus));
        });
      }
      // eslint-disable-next-line no-shadow
      variantTotal.value = Number(total.reduce((total, num) => total + num, 0));
    });
  });

const addToCart = async () => {
  const variants = [];

  Object.entries(selectedVariants.value).forEach(([ key, val ]) => {
    Object.values(val).forEach(v => variants.push({ id: v.id, category: Number(key) }));
  });

  if (Object.keys(selectedRestaurant.value)) {
    const tempCartId = sessionStorage.getItem('temp_cart_id');

    const params = {
      delivery_type: deliveryType.value,
      shop_id: selectedRestaurant.value?.id,
      ...((cartId.value || tempCartId) && { cart_id: cartId.value || tempCartId }),
      product: {
        id: product.value.id,
        qty: qty.value,
        variants,
      },
    };

    await store.dispatch(isAuthenticated.value ? 'cart/addToCart' : 'cart/addToTempCart', params);
  }
};

const getOptions = options => {
  const arr = [];

  options.forEach(d => {
    arr.push({
      label: `${d.name} ${d.price_plus !== '0.00' ? convertToNumber(d.price_plus) : ''}`,
      value: JSON.stringify(d),
    });
  });

  return arr;
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-item {
  $self: &;

  width: 100%;

  &__bannerImg {
    position: relative;
  }

  &::v-deep(.c-item__image) {
    height: 300px;
    object-fit: cover;
  }

  &__base-radio {
    @include mx.d-flex('flex-start', false, true);

    @include mx.bp('tablet-wide') {
      flex-direction: row;
    }
  }

  &__sectionContainer {
    padding-top: 5rem;
  }

  &__selectedRestaurant {
    margin-bottom: 50px;
  }

  &__gridContainer {
    display: grid;

    padding-bottom: 14rem;
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    row-gap: 10px;

    @include mx.bp('tablet-wide') {
      padding-bottom: 9rem;
      grid-template-columns: 70% 30%;
    }
  }

  &::v-deep(.body1) {
    @include mx.use-color('bodyColorLtLight');
    @include mx.font-size(14px);

    opacity: 0.7;
  }

  &::v-deep(.subtitle3) {
    text-transform: uppercase;
  }

  &::v-deep(.subtitle2) {
    margin-top: 0;
  }

  &::v-deep(.c-radio) {
    margin-right: inherit;

    border-bottom: none;

    @include mx.bp('tablet-wide') {
      margin-right: 4rem;
    }

    .c-radio__label {
      margin-left: 1.5rem;
      padding-bottom: 0;

      &:before {
        left: 0;
      }
    }
  }

  &__gridItem {
    height: max-content;

    &:nth-child(1) {
      order: 0;

      @include mx.bp('tablet-wide') {
        order: -1;
      }
    }

    &:nth-child(2) {
      order: -1;

      @include mx.bp('tablet-wide') {
        order: 0;
      }
    }

    &:nth-child(3) {
      order: 0;
    }

    &:nth-child(4) {
      order: 2;

      @include mx.bp('tablet-wide') {
        order: 0;
      }
    }

    &:nth-child(5) {
      grid-column: span 1;

      @include mx.bp('tablet-wide') {
        grid-column: span 2;
      }
    }

    &:nth-child(1),
    &:nth-child(3) {
      padding-right: 0;

      @include mx.bp('tablet-wide') {
        padding-right: 1rem;
      }
      @include mx.bp('desktop-wide') {
        padding-right: 4rem;
      }
    }

    &:nth-child(even) {
      padding-left: 0;

      @include mx.bp('tablet-wide') {
        padding-left: 1rem;
      }
      @include mx.bp('desktop-wide') {
        padding-left: 4rem;
      }
    }

    .-subTitle {
      @include mx.use-color('bodyColorLt');
      @include mx.font-size(12px, 14px);

      letter-spacing: 0;
      text-transform: uppercase;

      font-weight: bold;
    }
  }

  &::v-deep(.c-item__img) {
    width: 100%;
    height: auto;
  }

  &::v-deep(.c-baseButton) {
    &.-hasRightLabel {
      width: 100%;

      @include mx.bp('tablet') {
        width: 25%;
      }
    }
  }

  &::v-deep(.c-addRemoveItem) {
    padding-bottom: 24px;

    @include mx.bp('tablet') {
      padding-bottom: 0;
    }
  }

  &::v-deep(.c-item__ingredientsQty) {
    &.c-addRemoveItem {
      padding-bottom: 0;
    }
  }
}
</style>
