<template>
  <Typography
    variant="body1"
    v-text="`(Min: ${props.min} Max: ${props.max})`"
  />
  <div
    class="l-ingredientsList"
  >
    <CustomCheckBox
      v-for="item in list"
      :key="`${item.id}-${item.name}`"
      :label="item.name"
      :value="item.name"
      :id="`${props.groupName}-${item.id}-${item.name}`"
      :name="props.groupName"
      :content="item.price_plus && convertToNumber(item.price_plus)"
      :disabled="reachedLimit && !checkedItemsNames.includes(item.name)"
      v-model="item.checked"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';

import CustomCheckBox from '@/components/common/CustomCheckBox.vue';
import { convertToNumber } from '@/mixins/utils';

const props = defineProps({
  groupName: { type: String, required: true },
  checkboxesList: { type: Array, required: true },
  min: { type: [ Number, String ], required: true },
  max: { type: [ Number, String ], required: true },
});

const list = ref(props.checkboxesList.map(checkboxList => ({
  ...checkboxList,
  checked: false,
  disabled: false,
})));

const checkedItems = computed(() => list.value.filter(c => c.checked));
const checkedItemsNames = computed(() => checkedItems.value.map(n => n.name));
const reachedLimit = computed(() => checkedItems.value.length >= props.max);

const emit = defineEmits([ 'selected' ]);

watch(() => checkedItems.value,
  currState => {
    emit('selected', currState);
  });

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.l-ingredientsList {
  @include mx.use-custom-scroll(500px);

  @include mx.bp('tablet') {
    display: grid;
    grid-gap: 0 10px;
    grid-template-columns: 1fr 1fr;
  }

  @include mx.bp('desktop') {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
