<template>
  <div :class="['c-customCheckBox', modelValue && '-isChecked']">
    <span class="c-customCheckBox__border" />
    <input
      class="c-customCheckBox__input"
      type="checkbox"
      v-bind="$attrs"
      @change="selectedOption"
    >
    <label
      class="c-customCheckBox__label"
      :for="$attrs.id"
    >
      <span class="c-customCheckBox__textWrapper">
        <span>{{ props.label }}</span>
        <small v-if="props.content">{{ props.content }}</small>
      </span>
      <span class="c-customCheckBox__check" />
      <svg viewBox="0 0 21 21">
        <polyline points="5 10.75 8.5 14.25 16 6" />
      </svg>
    </label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
const props = defineProps({
  content: { type: String, default: '' },
  modelValue: {
    type: Boolean,
    required: true,
  },
  label: {
    type: [ String, Number ],
    required: true,
  },
});

const emit = defineEmits([ 'update:modelValue' ]);

const selectedOption = e => {
  emit('update:modelValue', e.target.checked);
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-customCheckBox {
  $self: &;

  --background: #FFFFFF;
  --border: #D1D6EE;
  --border-hover: #{lighten(v.$primary-color, 50%)};
  --border-active: #{v.$primary-color};
  --tick: #FFFFFF;

  position: relative;

  display: flex;
  overflow: hidden;

  width: 100%;

  cursor: pointer;
  transition: background-color 300ms ease-in-out;

  &__input,
  svg {
    display: block;

    width: 21px;
    height: 21px;
  }

  svg {
    --scale: 0;
    --stroke: var(--tick);

    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;

    margin: auto;

    transform: scale(var(--scale, 1)) translateZ(0);
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
  }

  &__input {
    --s: 1px;

    position: absolute;
    top: 50%;
    right: 20px;

    margin: 0;
    padding: 0;

    transition: box-shadow 0.3s;
    transform: translate(0, -50%);

    border: none;
    border-radius: 4px;
    outline: none;
    background: var(--background);
    box-shadow: inset 0 0 0 var(--s) var(--b, var(--border));
    appearance: none;

    &:hover {
      --s: 2px;
      --b: var(--border-hover);
    }

    &:checked {
      --b: var(--border-active);
      --s: 11px;

      & + #{ $self }__label {
        #{ $self }__border {
          transform: translateX(0);
        }

        #{ $self }__textWrapper {
          transform: translateX(5px);
        }

        svg {
          animation: bounce 0.4s linear forwards 0.2s;
        }
      }
    }

    &:disabled {
      & + #{ $self }__label {
        cursor: not-allowed;
      }
    }
  }

  &__label {
    --stroke: var(--tick);

    display: flex;
    flex: 1;
    align-items: center;

    padding: 20px 80px 20px 15px;

    cursor: pointer;
  }

  &__border {
    @include mx.use-bg-color('primary');

    width: 5px;
    height: auto;

    transition: transform 300ms ease-in-out;
    transform: translateX(-6px);

    align-self: stretch;
  }

  &:after {
    position: absolute;
    bottom: 0;

    display: block;

    width: 100%;

    content: '';

    border-bottom: 1px solid lightgray;
  }

  &__textWrapper {
    display: flex;
    flex-direction: column;

    transition: transform 300ms ease-in-out;

    small {
      @include mx.use-color('bodyColorLtLight');

      margin-top: 5px;
    }
  }

  &.-isChecked {
    background-color: transparentize(v.$body-color-light, 0.95);

    #{ $self }__border {
      transform: translateX(0);
    }
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }

  75% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
</style>
