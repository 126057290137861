<template>
  <div
    class="c-cart"
  >
    <SectionContainer
      size="lg"
    >
      <div class="c-cart__addToCart">
        <ItemQuantity
          @newQty="handleItemQty"
          :value="qty"
          :disabled="!!disabled"
        />
        <BaseButton
          variant="solid"
          color="primary"
          size="medium"
          :label-right="convertTotal(props.subTotal, locale)"
          :btn-label="$t('widgets.foodDetails.btnText')"
          :disabled="!!disabled"
          @click="handleNavigation"
        />
      </div>
    </SectionContainer>
  </div>
  <PickRestaurantModal
    :show-modal="showModal"
    @confirmed="confirmed"
  />
  <BaseModal
    :show-modal="showLoginModal"
  >
    <template #body>
      <ActiveAuthForm />
    </template>
  </BaseModal>
</template>

<script setup>

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BaseModal from '@/components/common/BaseModal.vue';
import ItemQuantity from '@/components/common/Cart/ItemQuantity.vue';
import PickRestaurantModal from '@/components/common/PickRestaurantModal.vue';
import ActiveAuthForm from '@/components/user/ActiveAuthForm.vue';
import Trans from '@/mixins/translation';
import { convertTotal } from '@/mixins/utils';
import { PATHS } from '@/router/constants';

const props = defineProps({
  subTotal: {
    type: [ Number, String ],
    default: 0,
  },
  addToCart: {
    type: Function,
    default: () => null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const store = useStore();
const { locale } = useI18n();

const qty = ref(1);
const selectedRestaurant = computed(() => store.state.restaurant.selectedRestaurant);
const isGuestMode = computed(() => store.getters['reusable/getSetting']('guest_mode'));
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const showModal = computed(() => store.state.overlays.openModal === 'PickRestaurant');
const showLoginModal = computed(() => store.state.overlays.openModal === 'LoginForm');
const isPerfectMatch = computed(() => store.state.isPerfectMatch);
const addToCartError = computed(() => store.state.cart.addToCartError);

const emit = defineEmits([ 'qty' ]);

const confirmed = () => {
  props.addToCart();
};
const handleItemQty = newQty => {
  qty.value = newQty;
  emit('qty', newQty);
};

const handleRedirection = () => {
  if (!addToCartError.value) {
    if (isPerfectMatch.value) {
      store.dispatch('setIsPerfectMatch', false);
      router.push(Trans.i18nRoutePath(PATHS.CHECKOUT));
    } else {
      router.push(Trans.i18nRoutePath(`/dashboard/${selectedRestaurant.value.id}`));
    }
  }
};

const handleNavigation = () => {
  if (isGuestMode.value || isAuthenticated.value) {
    if (!selectedRestaurant.value.id) {
      store.dispatch('overlays/setModal', 'PickRestaurant');
    } else {
      props.addToCart().then(() => {
        handleRedirection();
      });
    }
  } else {
    // open login modal
    store.dispatch('overlays/setModal', 'Login');
    store.dispatch('forms/setActiveForm', 'LoginForm');
  }
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-cart {
  position: fixed;
  bottom: 0;

  width: 100%;
  padding: 24px 0;

  background: var(--#{ v.$variable-prefix}bodyColorDt) no-repeat padding-box;
  box-shadow: v.$base-box-shadow;

  @include mx.use-zeta-index('content');

  &__addToCart {
    @include mx.d-flex("space-between", "center", true);

    @include mx.bp('tablet') {
      flex-direction: row;
    }
  }
}
</style>
